.step-by-step-body
	padding-left: calc(8rem + 280px)
	padding-top: 3rem
	padding-right: 8rem
	min-height: calc(100vh - 85px)

@include desktop-only
	.step-by-step-body
		padding-left: calc(4rem + 280px)
		padding-right: 0

@include tablet-only
	.step-by-step-body
		padding-left: calc(1rem + 280px)
		padding-right: 1rem

@include mobile
	.step-by-step-body
		padding-top: calc(3rem + 30px)
		padding-left: 1rem
		padding-right: 1rem
