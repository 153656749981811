* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* fix is-transparent navbar */
.navbar.is-transparent {
  background-color: transparent !important;
}
